.pro-feature {
  position: relative;
}

.pro-feature--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pro-feature__lock {
  font-size: 16px;
  margin-left: 8px;
  opacity: 0.7;
  vertical-align: middle;
}

.pro-feature__tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.pro-feature--disabled:hover .pro-feature__tooltip {
  opacity: 1;
}